import React, { useEffect, useState } from "react";
import "../../styles/Orders.css";
import { Link, useLocation } from "react-router-dom";
import { purchaseRefData } from "../../actions/usersActions";
import { GetInvestmentOrder } from "../../actions/adminAction";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

const Orders = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const [ordersData, setOrderData] = useState();
  const orderID = location.state?.orderId;
  const userId = location.state?.userId;
  console.log(location.state,"location.state");
  console.log(ordersData,"ordersData");

  const getQueryParams = (queryString) => {
    const params = new URLSearchParams(queryString);
    const queryObject = {};
    for (let [key, value] of params.entries()) {
      queryObject[key] = value;
    }
    return queryObject;
  };
  const queryParams = getQueryParams(location.search);
  useEffect(() => {
    if (orderID) {
      const payload = {
        orderId: orderID,
        userID: userId,
      }
      dispatch(GetInvestmentOrder(payload))
        .then((response) => {
          setOrderData(response);
          if (response?.status != true) {
            toast.error("Order is decaline, Please, try again");
          }
        })
        .catch((error) => toast.error("Order is decaline, Please, try again"));
    }
  }, [orderID]);

  const [timeAgoData, setTimeAgoData] = useState({});

  // Function to calculate "time ago"
  const calculateTimeAgo = (startTime) => {
    const now = Date.now();
    const diffInSeconds = Math.floor((now - startTime) / 1000);

    if (diffInSeconds >= 0) {
      if (diffInSeconds < 60) {
        return `${diffInSeconds} second${diffInSeconds > 1 ? "s" : ""} ago`;
      } else if (diffInSeconds < 3600) {
        const minutes = Math.floor(diffInSeconds / 60);
        return `${minutes} minute${minutes > 1 ? "s" : ""} ago`;
      } else if (diffInSeconds < 86400) {
        const hours = Math.floor(diffInSeconds / 3600);
        return `${hours} hour${hours > 1 ? "s" : ""} ago`;
      } else {
        const days = Math.floor(diffInSeconds / 86400);
        return `${days} day${days > 1 ? "s" : ""} ago`;
      }
    } else {
      return "Just now";
    }
  };

  useEffect(() => {
    const updatedTimeAgo = {};
    ordersData?.orders?.forEach((response) => {
      const startTime = new Date(response?.created_at).getTime(); // Use the timestamp field
      updatedTimeAgo[response?.unique_no] = calculateTimeAgo(startTime);
    });
    setTimeAgoData(updatedTimeAgo);
  }, [ordersData]);

  useEffect(() => {
    if (queryParams?.PaymentRefNo) {
      dispatch(purchaseRefData({ PaymentRefNo: queryParams?.PaymentRefNo }))
        .then((response) => {
          setOrderData(response);
          if (response?.status != true) {
            toast.error(
              "Order is decaline or order not found, Please, try again"
            );
          }
        })
        .catch((error) =>
          toast.error("Order is decaline or order not found, Please, try again")
        );
    }

    if (queryParams?.order) {
      dispatch(GetInvestmentOrder(queryParams?.order))
        .then((response) => {
          setOrderData(response);
          if (response?.status != true) {
            toast.error(
              "Order is decaline or order not found, Please, try again"
            );
          }
        })
        .catch((error) =>
          toast.error("Order is decaline or order not found, Please, try again")
        );
    }
  }, []);
  // Parse the query parameters from the location.search
  return (
    <div className="container-fluid page_background ">
      <div className="page-inner-content mb-3">
        <div className="page_inner_content_box ">
          <div
            style={{
              backgroundColor:
                queryParams?.PaymentStatus === "SUCCESS"
                  ? "green"
                  : queryParams?.PaymentStatus === "FAILURE"
                  ? "red"
                  : queryParams?.PaymentStatus === "PENDING"
                  ? "orange"
                  : "transparent",
              color:
                queryParams?.PaymentStatus === "SUCCESS"
                  ? "white"
                  : queryParams?.PaymentStatus === "FAILURE" ||
                    queryParams?.PaymentStatus === "PENDING"
                  ? "white"
                  : "black",
              paddingTop: "10px",
              paddingBottom: "0.08px",
              borderRadius: "5px",
              textAlign: "center",
            }}
          >
            <p>
              {queryParams?.PaymentStatus == "SUCCESS"
                ? "Order placed successfully!"
                : queryParams?.PaymentStatus == "FAILURE"
                ? "Order not placed, Please try again."
                : queryParams?.PaymentStatus == "PENDING"
                ? "Check after some time in transaction list"
                : ""}
            </p>
          </div>
          <h5 className="mb-4 main-heading-title mt-2">Orders</h5>

          {ordersData?.orders?.length > 0 ? (
            ordersData?.orders?.map((response) => {
              return (
                <div className="card mt-4 border-0 order_box">
                  <div className="d-flex column-gap-2  row-gap-2 flex-wrap align-items-center px-3 pt-3">
                    <div className="d-flex align-items-center">
                      <img
                        src={response?.icon}
                        alt="Fund Logo"
                        className="me-3 fund-logo"
                        width="50"
                      />

                      <Link to="/" className="">
                        <h5 className="mb-0 outerHeading">
                          {response?.scheme_name}
                        </h5>
                      </Link>
                    </div>
                    <div className="ms-sm-auto text-end currency2">
                      <h5 className="text_success mb-1 aditya_currency">
                        ₹ {response?.amt}
                      </h5>
                      <p className="mb-0  aditya_currency_time">
                        {timeAgoData[response?.unique_no] || "Just now"}
                      </p>
                    </div>
                  </div>

                  <div className="d-flex gap-3 transaction_content2 pb-3  mt-2 px-3">
                    <p className="mb-1  transaction_inner">
                      Transaction No.{" "}
                      <span className="d-block transaction_inner_value mt-1">
                        {response?.trxn_no}
                      </span>
                    </p>
                    <p className="mb-0 transaction_inner">
                      Ref.
                      <span className="d-block transaction_inner_value mt-1">
                        {response?.unique_no}
                      </span>
                    </p>
                  </div>
                </div>
              );
            })
          ) : (
            <p>Order not found</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default Orders;
