import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import "../../styles/profile.css";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { BrokerDetail } from "../../actions/adminAction";

// Functional component for the Home page
const MappingDetails = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const id = location.state;
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (id) {
      setIsLoading(true);
      dispatch(BrokerDetail(id)).finally(() => setIsLoading(false));
    }
  }, [id, dispatch]);

  const { adminCheckBroker = {} } = useSelector((state) => state?.admin);
  const data = adminCheckBroker?.data || {};
  console.log(data,"data");
  
  return (
    <div className="container-fluid page_background">
      <div className="page-inner-content mb-3">
        <div className="page_inner_content_box">
          <h5 className="mb-4 main-heading-title">
            Investor's Mapping Details
          </h5>
          <div className="card redeam_bottom_card mapping-details-box accordion-body1">
            {/* View Personal Details Content */}
            <div className="card redeam_bottom_card mapping-details-no-border accordion-body1">
              <div className="row form_body justify-content-center">
                <div className="col-xl-6 col-lg-10 col-md-10">
                  <form>
                    <div className="row align-items-center mb-lg-4 mb-md-4 mb-sm-4 mb-3 personalAccordian">
                      <div className="col-6 my-md-6 my-1">
                        <label className="form-label">IIN:</label>
                      </div>
                      <div className="col-6 my-md-6 my-1">
                        <span className="white-space-nowrap">{data?.id || "NA"}</span>
                      </div>
                    </div>

                    <div className="row align-items-center mb-lg-4 mb-md-4 mb-sm-4 mb-3 personalAccordian">
                      <div className="col-6 my-md-2 my-1">
                        <label className="form-label">Name:</label>
                      </div>
                      <div className="col-6 my-md-2 my-1">
                        <span className="white-space-nowrap">{data?.profile?.inv_name || "NA"}</span>
                      </div>
                    </div>

                    <div className="row align-items-center mb-lg-4 mb-md-4 mb-sm-4 mb-3 personalAccordian">
                      <div className="col-6 my-md-2 my-1">
                        <label className="form-label">IIN Status:</label>
                      </div>
                      <div className="col-6 my-md-2 my-1">
                        <span className="white-space-nowrap">{data?.profile?.iin_status || "NA"}</span>
                      </div>
                    </div>

                    <div className="row align-items-center mb-lg-4 mb-md-4 mb-sm-4 mb-3 personalAccordian">
                      <div className="col-6 my-md-2 my-1">
                        <label className="form-label">Broker Code:</label>
                      </div>
                      <div className="col-6 my-md-2 my-1">
                        <span className="white-space-nowrap">{data?.customer_broker_code || "NA"}</span>
                      </div>
                    </div>

                    <div className="row align-items-center mb-lg-4 mb-md-4 mb-sm-4 mb-3 personalAccordian">
                      <div className="col-6 my-md-2 my-1">
                        <label className="form-label">Our Code:</label>
                      </div>
                      <div className="col-6 my-md-2 my-1">
                        <span className="white-space-nowrap">{data?.client_broker_code || "NA"}</span>
                      </div>
                    </div>

                    <div className="row align-items-center mb-lg-4 mb-md-4 mb-sm-4 mb-3 personalAccordian">
                      <div className="col-6 my-md-2 my-1">
                        <label className="form-label">Mapped with us:</label>
                      </div>
                      <div className="col-6 my-md-2 my-1">
                        <span className="white-space-nowrap">{data?.match === true ? "Yes" : "No" || "NA"}</span>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MappingDetails;
