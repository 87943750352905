import React, { useEffect, useState } from "react";
import "../../styles/transaction.css";
import { IoSearch } from "react-icons/io5";
import Loader from "../../loader/Loader-ui";
import InvestButton from "../../components/InvestButton";
import MySipAndLumpsum from "../../pages/MySipAndLumpsum";
import SipAndLumpsumCart from "../../components/Admin/SipAndLumpsumCart";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import {
  GetInvestmentAMC,
  GetInvestmentInvestor,
  GetInvestmentFunds,
  GetInvestmentCartProductShow,
  GetInvestmentCartProceed,
  GetInvestmentCartProductAdd,
} from "../../actions/adminAction";
import { useFormik } from "formik";
import * as Yup from "yup";
import BuySipLumpsumModel from "./BuySipLumpsumModel"; // Modal import
import BuyLumpsumModel from "./BuyLumpsumModel";
import PopupModel from "../../components/PopupModel";
import cancel from "../../images/popup/alert-image.svg";
import { useNavigate } from "react-router-dom";

const AddInvestment = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [investorID, setInvestorID] = useState("");
  const [investorUserId, setInvestorUserId] = useState("");
  const [investorSearch, setInvestorSearch] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isFundLoading, setIsFundLoading] = useState(false);
  const [amcID, setAMCID] = useState("");
  const [schemType, setSchemType] = useState("");
  const [additionalType, setAdditionalType] = useState("");
  const [fundData, setFundData] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [openModel, setOpenModel] = useState(1);
  const [runPopUp, setRunPopUp] = useState(false);
  const [totalInvest, setTotalInvest] = useState(0);
  const handleModalOpen = () => setIsModalOpen(true); // Open Modal
  const handleModalClose = () => setIsModalOpen(false); // Close Modal
  const [disabled, setDisable] = useState(false);
  const [isPopupVisible, setIsPopupVisible] = useState(false); // Manage popup visibility
  const closeModal = () => {
    setIsPopupVisible(false);
  };
  const fetchInvestmentInvestorList = async (customerId = "", invName = "") => {
    try {
      setIsLoading(true);
      await dispatch(
        GetInvestmentInvestor({
          customerId,
          invName,
        })
      );
    } catch (error) {
      console.error("Error fetching investor list:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchInvestmentAmcList = async () => {
    try {
      setIsLoading(true);
      await dispatch(GetInvestmentAMC());
    } catch (error) {
      console.error("Error fetching AMC list:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchInvestmentCartProductShow = async () => {
    try {
      setIsLoading(true);
      const payload = {
        user_id: investorUserId,
      };
      await dispatch(GetInvestmentCartProductShow(payload));
    } catch (error) {
      console.error("Error fetching AMC list:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchInvestmentFundList = async () => {
    try {
      setIsFundLoading(true);
      const payload = {
        user_id: investorUserId,
        search_query: "",
        category: schemType.toLowerCase(),
        amc: amcID,
      };
      await dispatch(GetInvestmentFunds(payload));
    } catch (error) {
      console.error("Error fetching AMC list:", error);
    } finally {
      setIsFundLoading(false);
    }
  };

  useEffect(() => {
    // if (investorUserId) {
    fetchInvestmentCartProductShow();
    // }
  }, [dispatch, investorUserId]);

  useEffect(() => {
    fetchInvestmentInvestorList(investorID, investorSearch);
  }, [dispatch, investorID, investorSearch]);

  useEffect(() => {
    fetchInvestmentAmcList();
  }, [dispatch]);

  useEffect(() => {
    fetchInvestmentFundList();
  }, [dispatch, investorUserId, schemType, amcID]);

  const { adminInvestmentAmc = {} } = useSelector((state) => state?.admin);
  const { adminInvestmentInvestor = {} } = useSelector((state) => state?.admin);
  const { adminInvestmentFund = {} } = useSelector((state) => state?.admin);
  const { adminInvestmentCartProductShow = {} } = useSelector(
    (state) => state?.admin
  );

  const amcList = adminInvestmentAmc?.data || [];
  const investorList = adminInvestmentInvestor?.data || [];
  const fundsList = adminInvestmentFund?.data?.products || [];
  const cartProductShow = adminInvestmentCartProductShow?.data || {};

  const handleAMCIdChange = (e) => {
    setAMCID(e.target.value);
  };

  const handleAddToCart = async () => {
    if (!investorUserId || !fundData) {
      toast.error("Please select both User ID and Scheme.");
      return;
    }
    try {
      setIsLoading(true);
      const payload = {
        user_id: investorUserId,
        product_id: fundData?.id,
        amc_product_id: fundData?.amc_product_id,
        amc_code: fundData?.amc_code,
        product_code: fundData?.product_code,
        isin: fundData?.isin,
        is_additional_purchase: false,
        reinvest: fundData?.reinvest_tag,
      };

      const response = await dispatch(GetInvestmentCartProductAdd(payload));
      if (response.status === true) {
        toast.success(response?.message);
        fetchInvestmentCartProductShow();
        // setInvestorUserId("");
        // setInvestorID("");
        // setInvestorSearch("");
        // setAMCID("");
        setFundData(null);
        // setAdditionalType("");
        // setSchemType("");
      } else {
        toast.error(response?.message);
      }
    } catch (error) {
      console.error("Error adding product to cart:", error);
      toast.error("Error adding product to cart.");
    } finally {
      setIsLoading(false);
    }
  };
  const formik = useFormik({
    initialValues: {
      ...cartProductShow,
      products_in_cart: cartProductShow?.products_in_cart?.map((product) => ({
        ...product,
        amount:
          product?.sipProductlimit == null
            ? product?.lumpsumProductlimit?.minimum_amount
            : product?.sipProductlimit?.minimum_amount, // Add default values for new fields
        invest_type: product?.sipProductlimit == null ? "lumpsum" : "sip",
        reinvest:
          product?.cart_product?.product?.reinvest_tag == "X"
            ? ""
            : product?.cart_product?.product?.reinvest_tag,
        until_cancel: 0,
      })),
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      products_in_cart: Yup.array().of(
        Yup.object({
          invest_type: Yup.string().required("Investment type is required"),
          // folio: Yup.string().when("invest_type", (invest_type, schema) => {
          //   return invest_type[0] === "lumpsum" || invest_type[0] === "sip"
          //     ? schema.required("Folio is required")
          //     : schema;
          // }),
          amount: Yup.string().when("invest_type", (invest_type, schema) => {
            return invest_type[0] === "lumpsum" || invest_type[0] === "sip"
              ? schema
                  .required("Amount is required")
                  .test("min-amount", function (value) {
                    const {
                      invest_type,
                      sipProductlimit,
                      lumpsumProductlimit,
                    } = this.parent || {};
                    const minAmount =
                      invest_type === "sip"
                        ? sipProductlimit?.minimum_amount
                        : invest_type === "lumpsum"
                        ? lumpsumProductlimit?.minimum_amount
                        : 0;

                    // If minimum amount is not met, return a custom error message
                    if (!value || Number(value) < minAmount) {
                      return this.createError({
                        path: this.path,
                        message: `Amount must be at least ${minAmount}`,
                      });
                    }

                    // If validation passes, return true
                    return true;
                  })
              : schema;
          }),
          day: Yup.string()
            .notOneOf(["Select"], "Day is required")
            .when("invest_type", (invest_type, schema) => {
              return invest_type[0] === "sip"
                ? schema.required("Day is required")
                : schema;
            }),

          frequency: Yup.string()
            .notOneOf(["Select"], "frequency is required")
            .when("invest_type", (invest_type, schema) => {
              return invest_type[0] === "sip"
                ? schema.required("frequency is required")
                : schema;
            }),

          noofinstallment: Yup.string().when(
            ["invest_type", "until_cancel"],
            (values, schema) => {
              const [invest_type, until_cancel] = values;
              if (invest_type === "sip" && until_cancel == "0") {
                return schema
                  .required("No of Installment is required")
                  .notOneOf(["Select"], "No of Installment is required");
              }

              return schema;
            }
          ),

          // noofinstallment: Yup.string()
          //   .notOneOf(["Select"], "No of Installment is required")
          //   .when("invest_type", (invest_type, schema) => {
          //     return invest_type[0] === "sip"
          //       ? schema.required("No of Installment is required")
          //       : schema;
          //   }),
          stepup: Yup.string(),
          step_amt: Yup.string().when(
            ["invest_type", "stepup"],
            (values, schema) => {
              const [invest_type, stepup] = values;
              if (invest_type === "sip") {
                if (stepup == "true") {
                  return schema.required("Step amount is required");
                }
                return schema.notRequired();
              }
              return schema;
            }
          ),
          step_frequency: Yup.string()
            .notOneOf(["Select"], "Step frequency is required")
            .when(["invest_type", "stepup"], (values, schema) => {
              const [invest_type, stepup] = values;
              if (invest_type === "sip") {
                if (stepup == "true") {
                  return schema.required("Step frequency is required");
                }
                return schema.notRequired();
              }
              return schema;
            }),
          reinvest: Yup.string()
            .notOneOf(["Select"], "Reinvest is required")
            .required("Reinvest is required"),
          final_amt: Yup.string().when(
            ["invest_type", "stepup"],
            (values, schema) => {
              const [invest_type, stepup] = values;
              if (invest_type === "sip") {
                if (stepup == "true") {
                  return schema.required("Final amount is required");
                }
                return schema.notRequired();
              }
              return schema;
            }
          ),
          folioList: Yup.array(),
        })
      ),
    }),
    onSubmit: (values) => {
      setDisable(true);
      const payload = [];
      values?.products_in_cart?.map((res) => {
        payload.push({
          minimum_amount:
            res?.invest_type == "sip"
              ? res?.sipProductlimit?.minimum_amount == undefined
                ? "0"
                : res?.sipProductlimit?.minimum_amount
              : res?.invest_type == "lumpsum"
              ? res?.lumpsumProductlimit?.minimum_amount == undefined
                ? "0"
                : res?.lumpsumProductlimit?.minimum_amount
              : "0",
          productlimit_id: res?.sipProductlimit?.id,
          product_id: res?.cart_product?.product?.id,
          amc_code: res?.cart_product?.product?.amc_code,
          product_code: res?.cart_product?.product?.product_code,
          amc_product_id: res?.cart_product?.product?.amc_product_id,
          isin: res?.cart_product?.product?.isin,
          investment_mode:
            res?.invest_type == "sip"
              ? "S"
              : res?.invest_type == "lumpsum"
              ? "N"
              : "",
          wizard_status: values?.isWizardCompleted,
          is_additional_purchase:
            res?.cart_product?.trxn_type == "FP"
              ? false
              : res?.cart_product?.trxn_type == "AP"
              ? true
              : "",
          folioNumber: res?.folionumber,
          date_installment: res?.day,
          frequency: res?.frequency,
          tenure: res?.noofinstallment,
          reinvest: res?.reinvest
            ? res?.reinvest
            : res?.cart_product?.product?.reinvest_tag,
          amount: res?.amount,
          stepup: res?.stepup == undefined ? "" : res?.stepup,
          stepup_freq:
            res?.step_frequency == undefined ? "" : res?.step_frequency,
          stepup_amount: res?.step_amt == undefined ? "" : res?.step_amt,
          // final_amount: res?.final_amt == undefined ? 0 : 0,
          until_cancel: res?.invest_type == "sip" ? res?.until_cancel : 0,
        });
      });

      const finalResponse = {
        user_id: investorUserId,
        products_in_cart: payload,
      };
      dispatch(GetInvestmentCartProceed(finalResponse))
        .then((response) => {
          if (response) {
            if (response?.status != true) {
              setDisable(false);
              setIsPopupVisible(true);
              // toast.error("Please check Bank added or not!");
            } else {
              setDisable(false);
            }
          } else {
            setIsPopupVisible(true);
            // toast.error("Please check Bank added or not!");
            setDisable(false);
          }
        })
        .catch((error) => {
          setIsPopupVisible(true);
          // toast.error("Please check Bank added or not!");
          setDisable(false);
        });
      setRunPopUp(true);
      setOpenModel(2)
    },
  });

  useEffect(() => {
    if (cartProductShow?.products_in_cart) {
      const updatedValues = {
        ...cartProductShow,
        products_in_cart: cartProductShow?.products_in_cart.map((product) => ({
          ...product,
          amount:
            product?.sipProductlimit == null
              ? product?.lumpsumProductlimit?.minimum_amount
              : product?.sipProductlimit?.minimum_amount,
          invest_type: product?.sipProductlimit == null ? "lumpsum" : "sip",
          reinvest:
            product?.cart_product?.product?.reinvest_tag == "X"
              ? ""
              : product?.cart_product?.product?.reinvest_tag,
          until_cancel: 0,
        })),
      };
  
      formik.setValues(updatedValues);
    }
  }, [cartProductShow]);
  

  // Function to clear all filters
  const clearFilters = () => {
    setInvestorUserId("");
    setInvestorID("");
    setInvestorSearch("");
    setAMCID("");
    setFundData(null);
    setAdditionalType("");
    setSchemType("");

    // Reset cart data
    formik.resetForm(); // Reset form values
    // fetchInvestmentCartProductShow(); // Re-fetch cart data
  };
  
  // useEffect(() => {
  //   if (formik?.values?.products_in_cart && runPopUp === true) {
  //     console.log("check ---------------");
      
  //     const investTypeFlag = formik?.values?.products_in_cart.some(
  //       (product) => product.invest_type === "sip"
  //     )
  //       ? setOpenModel(2)
  //       : setOpenModel(2);
        
  //     handleModalOpen();
  //   }
  // }, [runPopUp, formik?.values?.products_in_cart]);

  const actionPopup = (val) => {
    if (val != 1) {
      navigate("/admin/investor");
    }
  };

  useEffect(() => {
    setTotalInvest(0);
    let previousValue = 0;

    formik?.values?.products_in_cart?.forEach((res) => {
      const newValue = parseInt(res?.amount) || 0; // Handle cases where amount might be null or undefined
      // Update the total investment
      setTotalInvest((prevTotal) => {
        const updatedTotal = prevTotal + newValue;
        previousValue = newValue; // Update the previous value for the next iteration
        return updatedTotal;
      });
    });
  }, [formik?.values]);

  return (
    <div className="container-fluid page_background">
      {isFundLoading && (
        <div className="loader-overlay">
          <Loader />
        </div>
      )}
      <div className="page-inner-content mb-3">
        <div className="page_inner_content_box p-4">
          <h5 className="mb-4 main-heading-title">Add Investment</h5>

          <div className="row align-items-center mb-3">
            <div className="col-md-2 position-relative">
              <label className="dropdown-label-custom">Investor ID:</label>
              <div className="position-relative">
                <IoSearch className="search-icon-custom" />
                <input
                  type="text"
                  className="search-input-custom"
                  placeholder="Search Investors"
                  value={investorID}
                  onChange={(e) => setInvestorID(e.target.value)}
                />
              </div>
            </div>
            <div className="col-auto d-flex align-items-center justify-content-center px-2">
              <span className="or-text">OR</span>
            </div>{" "}
            <div className="col-md-3">
              <label className="dropdown-label-custom">Investors:</label>
              <select
                className="form-select custom-select2"
                displayEmpty
                value={investorUserId}
                onChange={(e) => setInvestorUserId(e.target.value)}
              >
                <option value="" disabled>
                  Select
                </option>
                {investorList?.map((rm, index) => (
                  <option key={index} value={rm.user_id}>
                    {rm.inv_name}
                  </option>
                ))}
              </select>
            </div>
            <div className="col-md-2">
              <label className="dropdown-label-custom">AMC:</label>
              <select
                className="form-select custom-select2"
                value={amcID} // Set selected value
                onChange={handleAMCIdChange} // Handle selection
                displayEmpty
              >
                <option value="" disabled>
                  Select
                </option>
                {amcList?.map((rm, index) => (
                  <option key={index} value={rm.amc_code}>
                    {rm.long_name}
                  </option>
                ))}
              </select>
            </div>
            <div className="col-md-2">
              <label className="dropdown-label-custom">Scheme Type:</label>
              <select
                className="form-select custom-select2"
                value={schemType} // Set selected value
                onChange={(e) => setSchemType(e.target.value)}
                displayEmpty
              >
                <option value="" disabled>
                  Select
                </option>
                {["Equity", "Debt", "Balanced", "Liquid", "ELSS"].map(
                  (category, index) => (
                    <option key={index} value={category}>
                      {category}
                    </option>
                  )
                )}
              </select>
            </div>
            {/* <div className="col-md-2">
              <label className="dropdown-label-custom">Transaction Type:</label>
              <select
                className="form-select custom-select2"
                value={additionalType} // Set selected value
                onChange={(e) => setAdditionalType(e.target.value)}
                displayEmpty
              >
                <option value="" disabled>
                  Select
                </option>
                {["Fresh", "Additional"].map((category, index) => (
                  <option key={index} value={category}>
                    {category}
                  </option>
                ))}
              </select>
            </div> */}
          </div>

          <div className="row mb-4">
            <div className="col-md-6">
              <label className="dropdown-label-custom">Scheme:</label>
              <select
                className="form-select custom-select2"
                value={fundData ? JSON.stringify(fundData) : ""}
                onChange={(e) =>
                  setFundData(
                    e.target.value ? JSON.parse(e.target.value) : null
                  )
                }
              >
                <option value="" disabled>
                  Select
                </option>
                {fundsList?.map((rm, index) => (
                  <option key={index} value={JSON.stringify(rm)}>
                    {rm.product_long_name}
                  </option>
                ))}
              </select>
              {/* Display fund info when selected */}
              {fundData && (
                <div className="mt-2 text-muted small">
                  <strong>NAV as on {fundData?.nav?.date || "--"}:</strong> ₹
                  {typeof fundData?.nav?.nav ===
                      "number"
                        ? fundData?.nav?.nav.toFixed(2)
                        : "--"} |
                  <strong> Min. SIP Amount:</strong> ₹
                  {fundData.productlimits?.find(
                    (limit) => limit.sub_trxn_type === "S"
                  )?.minimum_amount || "--"}
                  {" "}
                  |
                  {/* {fundData?.minimum_sip_amount || "--"} | */}
                  <strong> Min. Lumpsum Amount:</strong> ₹
                  {/* {fundData?.minimum_purchase_amount || "--"} */}
                  {fundData.productlimits?.find(
                    (limit) => limit.sub_trxn_type === "N"
                  )?.minimum_amount || "--"}
                </div>
              )}
            </div>
          </div>
          <div className="investor-btn-container d-flex justify-content-center align-items-center">
            <div className="d-flex gap-3">
              <InvestButton
                label={<>Proceed</>}
                color="blue"
                className="white-space-nowrap investor-btn-p-6 w-auto px-4"
                onClick={handleAddToCart}
                disabled={!fundData || !investorUserId || isLoading} // Disable if missing fund or investor ID
              />
              <InvestButton
                label={<>Clear</>}
                color="blue"
                onClick={clearFilters}
                className="white-space-nowrap investor-btn-p-10"
              />
            </div>
          </div>
          <div className="order_box pt-2">
            <form onSubmit={formik.handleSubmit}>
              <div className="mb-4">
                <SipAndLumpsumCart
                  values={formik?.values?.products_in_cart}
                  handleChange={formik?.handleChange}
                  setFieldValue={formik?.setFieldValue}
                  formik={formik}
                  resetForm={formik?.resetForm}
                />
              </div>
              {formik?.values?.products_in_cart?.length > 0 && (
                <div className="cart-bottom d-flex">
                  <p className="mb-0">
                    Total Investment Amount{" "}
                    <span className="">₹{totalInvest}</span>
                  </p>
                  <button
                    type="submit"
                    className="form_button next_btn"
                    disabled={disabled}
                    onClick={handleModalOpen} // Open modal on click
                  >
                    Proceed
                  </button>
                </div>
              )}
            </form>
          </div>
        </div>
      </div>
      {/* Pass state and close function to modal component */}
      {openModel === 2 ? (
        <BuySipLumpsumModel
          isModalOpen={isModalOpen}
          handleModalClose={handleModalClose}
          amt={totalInvest}
          user_id={investorUserId}
          // handleOtpVerify={handleOtpVerify} // Pass OTP verify function
          // isOtpVerified={isOtpVerified} // Pass OTP state
        />
      ) : (
        ""
        // <BuySipLumpsumModel
        //   isModalOpen={isModalOpen}
        //   handleModalClose={handleModalClose}
        //   amt={totalInvest}
        //   user_id={investorUserId}
        //   // handleOtpVerify={handleOtpVerify} // Pass OTP verify function
        //   // isOtpVerified={isOtpVerified} // Pass OTP state
        // />
        // <BuyLumpsumModel
        //   isModalOpen={isModalOpen}
        //   handleModalClose={handleModalClose}
        //   amt={totalInvest}
        //   user_id={investorUserId}
        // />
      )}

      {/* {isPopupVisible && (
        <PopupModel
          id="alertModel"
          title="Information"
          content={"You have not registered for eNACH, please register."}
          image={cancel}
          actionPopup={actionPopup}
          closeModal={closeModal}
          btnColor={"btn-success"}
          later={"btn-primary"}
        />
      )} */}
    </div>
  );
};

export default AddInvestment;
