// Action types for users
export const USER_LOGIN = "USER_LOGIN";
export const USER_RESENDOTP = "USER_RESENDOTP";
export const USER_START = "USER_START";
export const USER_VERIFY_LOGIN = "USER_VERIFY_LOGIN";
export const USER_FORGOT_PASSWORD = "USER_FORGOT_PASSWORD";
export const USER_SIGN_UP = "USER_SIGN_UP";
export const USER_CHANGE_PASSWORD = "USER_CHANGE_PASSWORD";
export const USER_ONBOARD_GET_DATA = "USER_ONBOARD_GET_DATA";
export const USER_ONBOARD_STORE_DATA = "USER_ONBOARD_STORE_DATA";
export const PINCODE = "PINCODE";
export const BANK_DETAIL = "BANK_DETAIL";
export const USER_GET_PROFILE = "USER_GET_PROFILE";
export const USER_STORE_PROFILE = "USER_STORE_PROFILE";
export const USER_STORE_PROFILE_UPDATE = "USER_STORE_PROFILE_UPDATE";
export const USER_BANK_DETAIL = "USER_BANK_DETAIL";
export const USER_BANK_DETAIL_UPDATE = "USER_BANK_DETAIL_UPDATE";
export const USER_BANK_DETAIL_ADD = "USER_BANK_DETAIL_ADD";
export const USER_FATCA_DETAIL = "USER_FATCA_DETAIL";
export const USER_FATCA_DETAIL_UPDATE = "USER_FATCA_DETAIL_UPDATE";
export const USER_DOCUMENTS = "USER_DOCUMENTS";
export const USER_DOCUMENTS_UPDATE = "USER_DOCUMENTS_UPDATE";
export const USER_NOMINEE_DETAIL = "USER_NOMINEE_DETAIL";
export const USER_NOMINEE_DETAIL_ADD = "USER_NOMINEE_DETAIL_ADD";
export const USER_ENACH_REGISTRATION_GET = "USER_ENACH_REGISTRATION_GET";
export const USER_ENACH_REGISTRATION_STORE = "USER_ENACH_REGISTRATION_STORE";
export const USER_ENACH_REGISTRATION_CREATE = "USER_ENACH_REGISTRATION_CREATE";
export const USER_ENACH_REGISTRATION_MAP = "USER_ENACH_REGISTRATION_MAP";
export const USER_ENACH_REGISTRATION_DELETE_MANDATE =
  "USER_ENACH_REGISTRATION_DELETE_MANDATE";
export const ORDER_ID = "ORDER_ID";
export const PRODUCT_REF = "PRODUCT_REF";

export const MESSAGE_TYPE = "MESSAGE_TYPE";
export const MESSAGE_SEND = "MESSAGE_SEND";
export const GET_MESSAGE = "GET_MESSAGE";

export const GET_FAQS = "GET_FAQS";
export const TERMS_CONDITIONS = "TERMS_CONDITIONS";

export const GET_CARD = "GET_CARD";
export const PROCEED_CARD = "PROCEED_CARD";
export const NACH_SEND_OTP = "NACH_SEND_OTP";
export const NACH_VERIFY_OTP = "NACH_VERIFY_OTP";
export const PURCHASE_CART = "PURCHASE_CART";
export const DASHBOARD_VALUATION = "DASHBOARD_VALUATION";
export const DASHBOARD_DATA = "DASHBOARD_DATA";

export const GET_WATCH_LIST = "GET_WATCH_LIST";
export const DELETE_WATCH_LIST = "DELETE_WATCH_LIST";

export const GET_EXPLORE_FUNDS_LIST = "GET_EXPLORE_FUNDS_LIST";

export const GET_GOAL_LIST = "GET_GOAL_LIST";
export const GET_GOAL_TYPE = "GET_GOAL_TYPE";
export const ADD_UPDATE_GOALS = "ADD_UPDATE_GOALS";
export const AMOUNT_GOAL_CALCULATION = "AMOUNT_GOAL_CALCULATION";
export const SIP_CALCULATOR = "SIP_CALCULATOR";
export const LUMPSUM_CALCULATOR = "LUMPSUM_CALCULATOR";
export const DELETE_GOAL = "DELETE_GOAL";
export const GOAL_DATA_BY_ID = "GOAL_DATA_BY_ID";

export const GET_AMC_LISTS = "GET_AMC_LISTS";
export const HOLDINGS_DETAILS = "HOLDINGS_DETAILS";
export const HOLDINGS_TYPES_DETAILS = "HOLDINGS_TYPES_DETAILS";
export const REDEEM_SHOW = "REDEEM_SHOW";
export const REDEEM_STORE = "REDEEM_STORE";
export const STP_SHOW = "STP_SHOW";
export const STP_STORE = "STP_STORE";
export const STP_SCHEME = "STP_SCHEME";
export const SWP_SHOW = "SWP_SHOW";
export const SWP_STORE = "SWP_STORE";
export const SWITCH_SHOW = "SWITCH_SHOW";
export const SWITCH_SCHEME = "SWITCH_SCHEME";
export const SWITCH_STORE = "SWITCH_STORE";

export const TRANSACTION_MAILBACK = "TRANSACTION_MAILBACK";
export const TRANSACTION_ONLINE = "TRANSACTION_ONLINE";

export const GET_EXPLORE_FUNDS_DETAIL = "GET_EXPLORE_FUNDS_DETAIL";
export const UPDATE_EXPLORE_FUNDS_LIST = "UPDATE_EXPLORE_FUNDS_LIST";

export const GET_NAV_CHART = "GET_NAV_CHART";

export const GET_SIMILAR_FUNDS = "GET_SIMILAR_FUNDS";
export const CHANGE_ADD_TO_CART_STATUS = "CHANGE_ADD_TO_CART_STATUS";
export const CHANGE_WATCHLIST_STATUS = "CHANGE_WATCHLIST_STATUS";

//My cart
export const CART_START = "CART_START";
export const ADD_MY_CART = "ADD_MY_CART";
export const DELETE_CART = "DELETE_CART";

export const RECOMMENDED_FUNDS = "RECOMMENDED_FUNDS";

export const SIP_CANCEL = "SIP_CANCEL";
export const SIP_PERIOD = "SIP_PERIOD";
export const SIP_PAUSE = "SIP_PAUSE";
export const GET_NOTIFICATION_LIST = "GET_NOTIFICATION_LIST";

// report
export const DATE_WISE_REPORT = "DATE_WISE_REPORT";
export const TYPE_WISE_REPORT = "TYPE_WISE_REPORT";
export const SCHEME_WISE_REPORT = "SCHEME_WISE_REPORT";
export const AMC_LIST = "AMC_LIST";
export const PRODUCT_LIST = "PRODUCT_LIST";
export const CAPGAIN_LOSS_REPORT = "CAPGAIN_LOSS_REPORT";

export const GET_HEADER_COUNT = "GET_HEADER_COUNT";

// admin
 export const ADMIN_DASHBOARD_SUMMARY = "ADMIN_DASHBOARD_SUMMARY";
 export const ADMIN_INVESTOR_LIST = "ADMIN_INVESTOR_LIST";
 export const ADMIN_INVESTOR_VIEW = "ADMIN_INVESTOR_VIEW";
 export const ADMIN_INVESTOR_LIST_EXPORT = "ADMIN_INVESTOR_LIST_EXPORT";
 export const ADMIN_INVESTMENT_LIST = "ADMIN_INVESTMENT_LIST";
 export const ADMIN_TRANSACTION_LIST = "ADMIN_TRANSACTION_LIST";
 export const CUSTOMER_LIST = "CUSTOMER_LIST";
 export const RM_LIST = "RM_LIST";
 export const FAQ_LIST = "FAQ_LIST";
 export const FAQ_DETAIL = "FAQ_DETAIL";
 export const FAQ_DETAIL_RESET = "FAQ_DETAIL_RESET";
 export const SUPPORT_LIST = "SUPPORT_LIST";
 export const SUPPORT_SHOW = "SUPPORT_SHOW";
 export const SUPPORT_DETAIL = "SUPPORT_DETAIL";
 export const SUPPORT_CLOSE = "SUPPORT_CLOSE";
 export const RM_DETAIL = "RM_DETAIL";
 export const RM_ASSIGN_LIST = "RM_ASSIGN_LIST";
 export const ADMIN_MAILBACK = "ADMIN_MAILBACK";
 export const CUSTOMER_STATUS_LIST = "CUSTOMER_STATUS_LIST";
 export const AMC_LIST_ADMIN = "AMC_LIST_ADMIN";
 export const PRODUCT_LIST_ADMIN = "PRODUCT_LIST_ADMIN";
 export const RM_BANK_LIST = "RM_BANK_LIST";
 export const ADMIN_EMAIL_UPDATE = "ADMIN_EMAIL_UPDATE";
 export const ADMIN_MOBILE_UPDATE = "ADMIN_MOBILE_UPDATE";
 export const RM_REFERRAL_CODE = "RM_REFERRAL_CODE";
 export const RM_SUMMARY_REPORT = "RM_SUMMARY_REPORT"
 export const CUSTOMER_LIST_RM = "CUSTOMER_LIST_RM";
 export const ADMIN_INVESTOR_ADD = "ADMIN_INVESTOR_ADD";
 export const ADMIN_INVESTOR_DETAIL = "ADMIN_INVESTOR_DETAIL";
 export const ADMIN_RESUBMIT_PROFILE = "ADMIN_RESUBMIT_PROFILE";
 export const ADMIN_ADD_INVESTOR_DATA = "ADMIN_ADD_INVESTOR_DATA";
 export const ADMIN_EDIT_INVESTOR_DATA = "ADMIN_EDIT_INVESTOR_DATA";
 export const ADMIN_FATCA_DATA = "ADMIN_FATCA_DATA";
 export const ADMIN_FATCA_LINK = "ADMIN_FATCA_LINK";
 export const ADMIN_IIN_LINK = "ADMIN_IIN_LINK";
 export const ADMIN_CHECK_BROKER = "ADMIN_CHECK_BROKER";
 export const BANK_DETAIL_IFSC = "BANK_DETAIL_IFSC";
 export const INVESTOR_RESET = "INVESTOR_RESET";
 export const ADMIN_INVESTMENT_AMC = "ADMIN_INVESTMENT_AMC";
 export const ADMIN_INVESTMENT_INVESTOR = "ADMIN_INVESTMENT_INVESTOR";
 export const ADMIN_INVESTMENT_FUND = "ADMIN_INVESTMENT_FUND";
 export const ADMIN_INVESTMENT_CART_PRODUCT_SHOW = "ADMIN_INVESTMENT_CART_PRODUCT_SHOW";
 export const ADMIN_INVESTMENT_CART_PRODUCT_ADD = "ADMIN_INVESTMENT_CART_PRODUCT_ADD";
 export const ADMIN_INVESTMENT_CART_PRODUCT_DELETE = "ADMIN_INVESTMENT_CART_PRODUCT_DELETE";
 export const ADMIN_INVESTMENT_CART_PRODUCT_PROCEED = "ADMIN_INVESTMENT_CART_PRODUCT_PROCEED";
 export const ADMIN_INVESTMENT_CART_PRODUCT_BUY = "ADMIN_INVESTMENT_CART_PRODUCT_BUY";